<template>
  <div class="md:w-1/2 mx-auto p-10 pp">
    <h4
      style="text-align: center"
      class="font-extrabold underline underline-offset-8"
    >
      KEBIJAKAN PRIVASI
    </h4>
    <p class="text-justify">&nbsp;</p>
    <div class="mb-8">
      <p class="text-justify">
        PT.Harapan Jaya Prima (&ldquo;kami&rdquo;) mengoperasikan Situs Web
        HJP&nbsp; (Layanan).
      </p>
      <p class="text-justify">
        Pada halaman ini menginformasikan kepada Pemilik Akun tentang kebijakan
        kami mengenai pengumpulan, penggunaan, dan pengungkapan data pribadi
        Pemilik Akun dalam menggunakan layanan kami dan pilihan yang telah
        dikaitkan oleh Pemilik Akun dengan data tersebut.
      </p>
      <p class="text-justify">
        Penggunaan data Pemilik Akun bertujuan untuk menyediakan dan
        meningkatkan Pelayanan kami terhahap Pemilik Akun. Dengan menggunakan
        Layanan kami, Pemilik Akun menyetujui pengumpulan dan penggunaan
        informasi sesuai dengan kebijakan ini.<br />
        Kami juga mengumpulkan beberapa jenis informasi lainnya untuk berbagai
        keperluan untuk menyediakan dan meningkatkan Pelayanan Kami kepada
        Pemilik Akun.
      </p>
    </div>
    <div class="mb-8">
      <p class="text-justify"><strong>Data Yang dikumpulkan :</strong></p>
      <p class="text-justify">Data yang di kumpulkan mencakup Data Pribadi Pemilik Akun, yakni :</p>
      <div class="pl-8">
        <ol>
          <li class="text-justify">Nama Lengkap</li>
          <li class="text-justify">Tanggal Lahir</li>
          <li class="text-justify">Jenis kelamin</li>
          <li class="text-justify">Alamat Lengkap</li>
          <li class="text-justify">Alamat Email</li>
          <li class="text-justify">Nomor telepon</li>
        </ol>
      </div>
    </div>
    <div class="mb-8">
      <p class="text-justify"><strong>Cara HJP memperoleh data Pribadi</strong></p>
      <p class="text-justify">
        HJP memperoleh Data Pribadi melalui aplikasi yang di unduh oleh Pemilik
        Akun. HJP dapat secara langsung menghubungi Pemilik Akun melalui
        Telepon, SMS, Whatsapp, ataupun Email, untuk meminta informasi tambahan
        yang cukup untuk memudahkan pelayanan penumpang pada Pemilik Akun. Dalam
        hal ini Pemilik Akun dianggap sudah setuju dengan ketentuan kebijakan
        ini
      </p>
    </div>
    <div class="mb-8">
      <p class="text-justify"><strong>Syarat yang dibutuhkan HJP dari Pemilik Akun</strong></p>
      <p class="text-justify">
        Setiap Pemilik Akun yang menggunakan aplikasi HJP, Dalam proses tersebut
        Pemilik Akun memberikan Data Pribadi ke aplikasi HJP. Secara khusus
        sebelum memberikan Data Pribadi , beberapa hal penting yang dibutuhkan
        oleh HJP adalah sebagai berikut :
      </p>
      <div class="pl-8">
        <ol>
          <li class="text-justify">
            Mendapatkan persetujuan dari Pemilik Akun sebelum megumpulkan,
            menggunakan dan menampilkan Data Pribadi
          </li>
          <li class="text-justify">
            Pemilik Akun Menjamin Data Pribadi sudah benar dan lengkap
            <strong><em>(tidak fiktif)</em></strong> untuk kelancaran pelayanan
            penumpang.
          </li>
        </ol>
      </div>
    </div>
    <div class="mb-8">
      <p class="text-justify"><strong>Penggunaan Data</strong></p>
      <div class="pl-8">
        <ol>
          <li class="text-justify">
            Harapan Jaya Prima menggunakan Data Pribadi Pemilik Akun yang telah
            diberikan dan disetujui untuk dikumpulkan/diadministrasikan dengan
            tujuan untuk melayani, memastikan pelayanan penumpang tepat sasaran
            dan tepat waktu, serta meningkatkan kualitas pelayanan. Dalam hal ini
            antara lain :
          </li>
          <li class="text-justify">
            Mengelola operasional dan administrasi serta mematuhi kebijakan dan
            prosedur HJP
          </li>
          <li class="text-justify">Menjalankan layanan penjualan karcis untuk Pemilik Akun</li>
          <li class="text-justify">Melacak dan menganalisa penjualan karcis Pemilik Akun</li>
          <li class="text-justify">
            Menginformasikan mengenai status pembayaran atau penumpang secara
            detail
          </li>
          <li class="text-justify">Memperbarui Informasi mengenai status pelayanan Pemilik Akun</li>
          <li class="text-justify">
            Menanggapi dan mengatasi pertanyaan dan permintaan dari Pemilik Akun
          </li>
          <li class="text-justify">
            Menginvestigasi terkait selisih jumlah penumpang, karcis palsu/ karcis
            expired
          </li>
          <li class="text-justify">
            Memberikan fasilitas komunikasi antara kondektur, kontrol, dan admin
          </li>
          <li class="text-justify">
            Melakukan analisa supaya lebih mengerti dan memahami lokasi, pilihan
            Pemilik Akun dan untuk meningkatkan pelayanan penjualan karcis
            penumpang
          </li>
          <li class="text-justify">
            Menyediakan media untuk pemberitahuan dan tanggapan dari Pemilik Akun
          </li>
          <li class="text-justify">
            Melakukan evaluasi berkaitan dengan penambahan produk, pembaruan
            informasi, pengembangan promosi layanan, dan pengembangan bisnis
            lainnya oleh HJP
          </li>
          <li class="text-justify">
            HJP dapat menghubungi Pemilik Akun melalui surat, email, SMS, telepon
            atau perangkat eletronik lainnya untuk tujuan diatas, serta untuk
            promo marketing dan tambahan produk dari HJP
          </li>
          <li class="text-justify">Maksud dan tujuan yang cukup terkait dengan perihal diatas.</li>
        </ol>
      </div>
    </div>
    <div class="mb-8">
      <p class="text-justify"><strong>Hubungi Kami</strong></p>
      <p class="text-justify">
        HJP hanya bisa memastikan perlindungan data secara penuh dengan bantuan
        Pemilik Akun.<br />
        Pemilik Akun dapat menghubungi Customer Service HJP kapanpun diperlukan.
        Menanyakan atau memberi saran terkait penggunaan, pengelolaan, dan
        perlindungan Data Pribadi Pemilik Akun.
      </p>
      <p class="text-justify">
        Silakan menghubungi Customer Service HJP melalui DM Instragram
        (@ptharapanjayaprima) atau Whatsapp 0815 1598 8889 yang telah tertera
        pada situs resmi HJP.
      </p>
    </div>
    <div class="mb-8">
      <p class="text-justify"><strong>Perubahan Dalam Kebijakan Privasi</strong></p>
      <p class="text-justify">
        HJP berhak mengubah kebijakan ini setiap saat apabila diperlukan,
        berdasarkan hukum yang berlaku dan kebijakan internal dari HJP.
      </p>
    </div>
    <div class="mb-8">
      <p class="text-justify"><strong>Hukum Yang Berlaku</strong></p>
      <div class="pl-8">
        <ul>
          <li class="text-justify">
            Segala perselisihan yang timbul dari pelaksaan Syarat dan Ketentuan
            serta layanan yang disediakan oleh HJP dengan Pemilik Akun akan
            diselesaikan secara musyawarah dan kekeluargaan ,kecuali ada hal
            lainya yang dianggap perlu untuk dilanjutkan melalui jalur hukum.
          </li>
          <li class="text-justify">
            Penyelesaian Perselisihan sebagaimana diatur dalam poin diatas, Layanan
            dan S&K ditentukan dan ditetapkan oleh HJP akan diselesaikan melalui
            yuridiksi Pengadilan Negeri di Tulungagung
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
